.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tglbtnFullWidth{
  margin-top: 0px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 90%;
}

.tglbtnStretch{
  width: 20%;
}

.tglbtnStretch3{
  width: 33%;
}

.logoWidth{
  margin: 15px;
  max-width: 35%;
}

.sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 99999
}

.ToggleButton.Mui-selected { /* Increase the specificity */
  color: #dbdbdb !important;
  background-color:#658494 !important;
}

.linear{
  background-color:#658494 !important;
}
.divided {
  font-size: 70%;
  color: darkgray;
  align-self: center;
  width: 90%;
  display: flex;
  align-items: center;
}

.divider {
  flex-grow: 1;
  border-bottom: 1px solid lightgray;
  margin: 5px
}